import { defineStyleConfig } from '@chakra-ui/react'

const Text = defineStyleConfig({
  variants: {
    mono: {
      fontFamily: `"Noto Sans Mono", monospace`,
    },
  },
})

export default Text
