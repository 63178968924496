import { defineStyleConfig } from '@chakra-ui/react'

const defaultStyles = {
  bgGradient: 'linear-gradient(to right, #3E3F87, #B95CB3)',
}

const disabledStyles = {
  ...defaultStyles,
  opacity: 0.4,
}

const Button = defineStyleConfig({
  variants: {
    payButton: {
      ...defaultStyles,
      _hover: {
        opacity: 0.8,
        _disabled: {
          ...disabledStyles,
        },
      },
      _active: {
        opacity: 0.6,
      },
    },
  },
})

export default Button
