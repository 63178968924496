import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EnumCurrencyNames } from "@bwg/utils-bwg"

export interface OrderState {
	uuid: string
	details: {
		expiredAt: number
		amount: number
		comment: string
		currencyCode: EnumCurrencyNames
		redirectUrls: {
			failUrl: string
			successUrl: string
		}
	}
	countryCode: "RU"
}

const initialState: OrderState = {
	uuid: "",
	details: {
		expiredAt: -1,
		amount: 0,
		comment: "",
		currencyCode: EnumCurrencyNames.RUB,
		redirectUrls: {
			failUrl: "",
			successUrl: "",
		},
	},
	countryCode: "RU",
}

export const orderSlice = createSlice({
	name: "counter",
	initialState,
	reducers: {
		setUUID: (state, action: PayloadAction<OrderState["uuid"]>) => {
			state.uuid = action.payload
		},
		setDetails: (state, action: PayloadAction<OrderState["details"]>) => {
			state.details = action.payload
		},
		setDetailsSuccessUrl: (state, action: PayloadAction<OrderState["details"]["redirectUrls"]["successUrl"]>) => {
			state.details.redirectUrls.successUrl = action.payload
		},
		setCountryCode: (
			state,
			action: PayloadAction<OrderState["countryCode"]>,
		) => {
			state.countryCode = action.payload
		},
		setFailUrl: (state, action: PayloadAction<OrderState["details"]["redirectUrls"]["failUrl"]>) => {
			state.details.redirectUrls.failUrl = action.payload
		},
		setSuccessUrl: (state, action: PayloadAction<OrderState["details"]["redirectUrls"]["successUrl"]>) => {
			state.details.redirectUrls.successUrl = action.payload
		},
		setExpiredAtOrder: (state, action: PayloadAction<OrderState["details"]["expiredAt"]>) => {
			state.details.expiredAt = action.payload
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setUUID,
	setDetails,
	setCountryCode,
	setDetailsSuccessUrl,
	setFailUrl,
	setSuccessUrl,
	setExpiredAtOrder,
} = orderSlice.actions

export default orderSlice.reducer
