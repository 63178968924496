import { configureStore } from '@reduxjs/toolkit'

// Slices
import order from './slices/order'
import overlay from './slices/overlay'
import form from './slices/form'

export const store = configureStore({
  reducer: { order, overlay, form },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
