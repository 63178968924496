export const Groups = {
  PAYMENT: '/repayment',
  FORM: '/form',
}

export const PathV1 = {
  GENERATE_INVOICE_H2H_CC: `${Groups.PAYMENT}/payment_p2p_final`,
  INVOICE_H2H_ORDER_DATA: `${Groups.PAYMENT}/payment_p2p_data`,
  CHECK_ORDER_STATUS: `${Groups.PAYMENT}/get_order_status`,
  CANCEL_INVOICE: `${Groups.PAYMENT}/abort`,
  SMS_BANK_INFO: `${Groups.PAYMENT}/sms_bank_info`,
  SEND_SMS_CODE: `${Groups.PAYMENT}/send_sms_code`,
}

export const PathV2 = {
  GENERATE_INVOICE_H2H_CC: `${Groups.FORM}/pay`,
  INVOICE_H2H_ORDER_DATA: `${Groups.FORM}/mount`,
  CHECK_ORDER_STATUS: `${Groups.FORM}/ping-status`,
}

export const AppRoute = {
  fail: "/fail",
  orderClose: "/order-closed",
  success: "/success",
  sendSms: "/send-sms",
}
