import { CircularProgress } from '@chakra-ui/react'
import { LoaderProps } from '../../models/components'

export default function Loader({ style }: LoaderProps) {
  return (
    <CircularProgress
      isIndeterminate
      style={style || {}}
      trackColor="whiteAlpha.300"
      color="rgba(0, 0, 0, 0.80)"
    />
  )
}
