/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface OverlayState {
  isLoaderShow: boolean
}

const initialState: OverlayState = {
  isLoaderShow: false,
}

export const orderSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setIsLoaderShow: (
      state,
      action: PayloadAction<OverlayState['isLoaderShow']>
    ) => {
      state.isLoaderShow = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setIsLoaderShow } = orderSlice.actions

export default orderSlice.reducer
