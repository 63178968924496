import { Center } from '@chakra-ui/react'
import CircleLoader from '../../components/loader/Loader'

export default function LoaderPage() {
  return (
    <Center h="100vh">
      <CircleLoader />
    </Center>
  )
}
