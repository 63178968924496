import React from 'react'
import ReactDOM from 'react-dom/client'

// App root element
import Root from './screens/root/Root'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
)
